<template>
  <v-layout>
    <v-app-bar app color="blue-grey darken-3" class="pl-0">
      <div class="d-flex align-center">
        <router-link to="/home" class="brand-logo">
          <v-img alt="ShowUsShows Logo" class="shrink logo-img" contain src="../assets/logo/brand-logo2.png"
            transition="scale-transition" />
        </router-link>
        <div class="phone-number d-flex align-center">
          <v-icon class="call-icon mr-1 white--text" small>mdi-phone</v-icon>
          561 510 9728
        </div>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn icon class="d-md-none" @click="menuDrawer = !menuDrawer">
        <v-icon color="#ffcc00">mdi-menu</v-icon>
      </v-btn> -->
      <div class="menu-items d-none d-md-flex">
        <router-link to="/home" class="menu-item">HOME</router-link>
        <router-link to="/gallery" class="menu-item">GALLERY</router-link>
        <router-link to="/contact" class="menu-item">CONTACT</router-link>
        <router-link to="/events" class="menu-item">FIND EVENTS</router-link>
        <router-link to="/add-event" class="menu-item">ADD YOUR EVENT</router-link>
      </div>
      <v-spacer></v-spacer>
      <div class="menu-item" :class="{'mr-3': isMidAndUp}" @click="handleHelp">HELP</div>
      <v-btn icon class="d-md-none" @click="menuDrawer = !menuDrawer">
        <v-icon color="#ffcc00" class="pl-0 ml-0">mdi-menu</v-icon>
      </v-btn>
      <div class="login-button">
        <v-avatar ref="avatar" v-if="isLoggedIn" class="menu-item avatar" @click.stop="toggleDrawer">
          <span>{{ userInitial }}</span>
        </v-avatar>
        <div v-else class="menu-item" @click="handleLoginLogout">{{ loginText }}</div>
      </div>
      <login-modal :openDialog="loginModal" @onLoginDone="handleLoginSuccess" @openSignUp="openSignUpDialog"
        @loginCancel="loginCancel" />
      <sign-up :openDialog="signUpModal" :userType="userType" @onSignupDone="hideSignupModal"
        @signupCancel="signupCancel" />
      <v-dialog v-model="showHelpDialog" max-width="1200" persistent>
        <help-comp :isPopup="isPopup" @close="closeHelpDialog" />
      </v-dialog>
    </v-app-bar>

    <!-- Mobile drawer for menu -->
    <v-navigation-drawer v-model="menuDrawer" app temporary class="mobile-drawer" right overlay width="250"
      color="blue-grey darken-3">
      <v-list>
        <v-list-item>
          <router-link to="/" class="menu-item">HOME</router-link>
        </v-list-item>
        <v-list-item>
          <router-link to="/gallery" class="menu-item">GALLERY</router-link>
        </v-list-item>
        <v-list-item>
          <router-link to="/contact" class="menu-item">CONTACT</router-link>
        </v-list-item>
        <v-list-item>
          <router-link to="/events" class="menu-item">FIND EVENTS</router-link>
        </v-list-item>
        <v-list-item>
          <router-link to="/add-event" class="menu-item">ADD YOUR EVENT</router-link>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Avatar drawer for settings and logout -->
    <v-navigation-drawer v-model="drawer" right temporary class="drawer" fixed :style="drawerPosition">
      <v-list>
        <v-list-item @click="navigateTo('/settings')" class="cursor-pointer">
          <v-list-item-action>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="handleLogout" class="cursor-pointer">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
  import axiosInstance from '@/utils/axiosConfig';
  import { mapState, mapActions } from 'vuex';
  import LoginModal from './auth/login-com';
  import SignUp from './auth/sign-up';
  import HelpComp from './help/help-comp';

  export default {
    components: {
      LoginModal,
      SignUp,
      HelpComp
    },
    data() {
      return {
        //isLoggedIn: false,
        loginModal: false,
        signUpModal: false,
        userType: null,
        drawer: false,
        menuDrawer: false, // Mobile menu drawer
        drawerPosition: {},
        showHelpDialog: false,
        isPopup: true,
      };
    },
    computed: {
      ...mapState(['isLoggedIn', 'userEmail']),
      loginText() {
        return this.isLoggedIn ? 'Log out' : 'Login';
      },
      userInitial() {
        return this.userEmail ? this.userEmail.charAt(0).toUpperCase() : '';
      },
      isMidAndUp() {
        // Check if the screen size is not xs
        return this.$vuetify.breakpoint.mdAndUp;
      },
    },
    created() {
      this.auth();
    },
    methods: {
      ...mapActions(['login', 'logout']),
      async auth() {
        //this.loading = true;
        await this.checkLogin();
        //this.isLoggedIn = loginStatus.success;
        //this.loading = false;

      },
      handleHelp(){
        this.showHelpDialog = true;
      },
      closeHelpDialog(){
        this.showHelpDialog = false;
      },
      handleLoginLogout() {
        if (this.isLoggedIn) {
          this.logoutFun();
        } else {
          this.loginModal = true;
        }
      },
      handleLoginSuccess(status) {
        this.loginModal = false;
        if (status) {
          //this.isLoggedIn = true;
          this.login();
        }
      },
      loginCancel() {
        this.loginModal = false;
      },
      openSignUpDialog() {
        this.loginModal = false;
        this.signUpModal = true;
        this.userType = null;
      },
      hideSignupModal() {
        this.signUpModal = false;
        this.loginModal = true;
      },
      signupCancel() {
        this.hideSignupModal();
      },
      navigateTo(route) {
        this.drawer = false;
        if (this.$route.name !== 'settings-view') {
          this.$router.push(route);
        }
      },
      async logoutFun() {
        try {
          await axiosInstance.post('user/logout', {}, {
            includeAuthToken: true,
          });
          this.logout();
          //this.isLoggedIn = false;
          if (this.$route.name !== 'home') {
            this.$router.push('/');
          }
        } catch (error) {
          console.error('Logout error:', error);
        }
      },
      handleLogout() {
        this.drawer = false;
        this.logoutFun();
      },
      toggleDrawer() {
        this.drawer = !this.drawer;
        if (this.drawer) {
          const avatar = this.$refs.avatar.$el;
          const rect = avatar.getBoundingClientRect();
          this.drawerPosition = {
            top: `${rect.bottom}px`,
            right: `${window.innerWidth - rect.right}px`
          };
        }
      }
    }
  };
</script>

<style scoped>
  .brand-logo {
    display: flex;
    align-items: center;
  }

  .logo-img {
    width: 200px;
    /* Default for larger screens */
  }

  .phone-number {
    font-size: 16px;
    /* Adjust font size as needed */
    font-weight: 500;
    color: #fff;
    /* Set the desired color */
    white-space: nowrap;
    /* Prevent wrapping */
  }

  .menu-items {
    display: flex;
    gap: 20px;
  }

  .menu-item {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
  }

  .menu-item:hover {
    color: #ffcc00;
    /* Highlight color on hover */
  }

  .login-button {
    display: flex;
    align-items: center;
  }

  .avatar {
    background-color: #ffcc00;
    color: black !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px !important;
    min-width: 40px !important;
    width: 40px !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .drawer {
    min-height: 120px !important;
    max-height: 120px !important;
    transition: none !important;
    border-radius: 4%;
    transform-origin: top right !important;
  }

  .mobile-drawer {
    z-index: 1000;
    /* Ensures it's on top of everything */
  }

  @media (max-width: 960px) {
    .menu-items {
      display: none;
      /* Hide menu items in mobile view */
    }
  }

  @media (max-width: 600px) {
    .menu-items {
      flex-direction: column;
      gap: 10px;
    }

    .logo-img {
      width: 120px;
      /* For mobile view */
    }
  }
</style>

<style>
  .v-toolbar__content {
    padding-left: 0px !important;
  }
</style>