<template>
  <div>
    <v-dialog v-model="showModal" persistent max-width="500px">
      <loader-comp :loading="loading" />
      <v-card v-if="screen1">
        <v-card-title class="headline">Sign up</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field v-model="firstName" label="First Name" required :rules="requiredFieldRules"></v-text-field>

            <v-text-field v-model="lastName" label="Last Name" required :rules="requiredFieldRules"></v-text-field>

            <v-text-field v-model="email" label="Email" required :rules="emailRules"></v-text-field>

            <v-text-field v-model="password" label="Password" :type="showPassword ? 'text' : 'password'" required
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword"
              :rules="passwordRules"></v-text-field>

            <v-text-field v-model="confirmPassword" label="Confirm Password"
              :type="showConfirmPassword ? 'text' : 'password'" required
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showConfirmPassword = !showConfirmPassword" :rules="confirmPasswordRules"></v-text-field>

            <!-- <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="phone"
                  v-bind="attrs"
                  v-on="on"
                  label="Phone (optional)"
                  :rules="phoneRules"
                ></v-text-field>
              </template>
              <span>Please provide a Phone Number if you want SMS notification.</span>
            </v-tooltip>            -->
            <v-text-field v-model="phone" label="Phone (optional)" :rules="phoneRules">
              <template #append>
                <v-tooltip left color="primary" max-width="200" contain>
                  <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                  </template>
                  <span>
                    Please provide your phone number if you want SMS notifications about events in your area.
                  </span>
                </v-tooltip>
              </template>
            </v-text-field>
            <div class="d-flex">
              <v-checkbox v-model="isAgree" hide-details></v-checkbox>
              <!-- <span v-html="checkboxLabel"></span> -->
              <span>
                Please provide your phone number to receive SMS notifications from showusshows.com about events in your area, including updates and cancellations. By providing your phone number, you consent to receive SMS notifications pending OTP verification sent to your number. You can opt-out at any time by replying STOP. Msg & Data Rates May Apply. Message frequency is estimated at 5-8 messages per month based on event activity. Read our
                <a href="#" @click.prevent="policyClick">Privacy Policy</a> and
                <a href="#" @click.prevent="termsClick">Terms of Use</a>.
              </span>
            </div>
            <!-- <p class="text-caption text--secondary font-italic mb-0">
              Please provide your phone number if you want SMS notifications about events in your area. By providing your phone number, you consent to receive SMS notifications. You can opt-out at any time by replying STOP to unsubscribe. Msg & Data Rates May Apply.
            </p> -->
            <v-text-field v-model="zipCode" label="Zip Code" required @change="fetchLocation"
              :rules="zipCodeRules"></v-text-field>

            <v-text-field v-model="city" label="City" required :rules="requiredFieldRules"></v-text-field>

            <v-select v-model="state" :items="states" label="State" :rules="requiredFieldRules" required></v-select>
          </v-form>

          <v-snackbar v-model="snackbarModal" :timeout="timeout" class="snackbar-modal" :color="snackbarColor">
            <div class="d-flex justify-space-between align-center">
              {{ snackbarMessageModal }}
              <v-btn color="white" text @click="snackbarModal = false">Close</v-btn>
            </div>
          </v-snackbar>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="cancel">Cancel</v-btn>
          <v-btn :disabled="!valid" color="primary" @click="subscribe">Submit</v-btn>
        </v-card-actions>
      </v-card>
      <div v-else>
        <verify-contact :email="email" :phone="phone" :type="type" @onVerificationDone="emailVerified"
          @verificationCancel="emailVerificationCancel" @onPhoneVerificationDone="phoneVerified"
          @phoneVerificationCancel="phoneVerificationCancel" />
      </div>
    </v-dialog>
    <v-dialog v-model="showTermsDialog" max-width="1200" persistent>
      <terms-condition :isPopup="isPopup" @close="closeTemsDialog" />
    </v-dialog>
    <v-dialog v-model="showPolicyDialog" max-width="1200" persistent>
      <privacy-policy :isPopup="isPopup" @close="closePolicyDialog" />
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">
      <div class="d-flex justify-space-between align-center">
        {{ snackbarMessage }}
        <v-btn color="white" text @click="snackbar = false">Close</v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
  import axiosInstance from '@/utils/axiosConfig';
  import VerifyContact from './verify-contact';
  import LoaderComp from '../shared/loader-comp';
  import TermsCondition from '../terms-condition/terms-condition-com';
  import PrivacyPolicy from '../privacy-policy/privacy-policy-com';
  export default {
    data() {
      return {
        isAgree: false,
        checkboxLabel: `Please provide your phone number to receive SMS notifications from showusshows.com about events in your area, including updates and cancellations. By providing your phone number, you consent to receive SMS notifications pending OTP verification sent to your number. You can opt-out at any time by replying STOP. Msg & Data Rates May Apply. Message frequency is estimated at 5-8 messages per month based on event activity. Read our <a href='/privacy-policy' target='_blank'>Privacy Policy</a> and <a @click="termsClick">Terms of Use</a>.`,
        loading: false,
        showModal: this.openDialog,
        screen1: true,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        zipCode: '',
        city: '',
        state: '',
        otp: '',
        valid: false,
        requiredFieldRules: [v => (v && !!v.trim()) || 'This field is required'],
        emailRules: [
          v => (v && !!v.trim()) || 'Email is required',
          v => (v && /.+@.+\..+/.test(v.trim())) || 'Email must be valid',
        ],
        phoneRules: [
          v => !v || (v && /^\d{10}$/.test(v.trim())) || 'Phone must be a valid 10-digit number',
        ],
        zipCodeRules: [
          v => (v && !!v.trim()) || 'Zip Code is required',
          v => (v && /^\d{5}$/.test(v.trim())) || 'Zip Code must be valid',
        ],
        passwordRules: [
          v => !!v || 'Password is required',
          v => (v && v.length >= 6) || 'Password must be at least 6 characters long',
        ],
        confirmPasswordRules: [
          v => !!v || 'Confirm Password is required',
          v => v === this.password || 'Passwords must match',
        ],
        states: [
          'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
        ],
        snackbar: false,
        snackbarColor: '',
        snackbarMessage: '',
        snackbarModal: false,
        snackbarMessageModal: '',
        timeout: 3000,
        showPassword: false,
        showConfirmPassword: false,
        password: '',
        confirmPassword: '',
        type: 'Email',
        isPopup: true,
        showTermsDialog: false,
        showPolicyDialog: false,
      };
    },
    props: {
      openDialog: Boolean,
    },
    watch: {
      openDialog(newVal) {
        this.showModal = newVal;
      },
    },
    components: {
      VerifyContact,
      LoaderComp,
      TermsCondition,
      PrivacyPolicy
    },
    methods: {
      termsClick(){
        this.showTermsDialog = true;
      },
      closeTemsDialog(){
        this.showTermsDialog = false;
      },
      policyClick(){
        this.showPolicyDialog = true;
      },
      closePolicyDialog(){
        this.showPolicyDialog = false;
      },
      async fetchLocation() {
        if (this.zipCode !== '' && /^\d{5}$/.test(this.zipCode.trim())) {
          this.city = '';
          this.state = '';
          const payload = {
            zip: this.zipCode.trim()
          };
          try {
            this.loading = true;
            const response = await axiosInstance.post('location/getByZip', payload);
            const location = response.data;
            this.city = location.city;
            this.state = location.state;
            this.loading = false;
          } catch (error) {
            this.snackbarMessageModal = 'Failed to load Location';
            this.snackbarColor = 'error';
            this.snackbarModal = true;
            this.loading = false;
          }
        }
      },
      isPhoneAndAgree() {
        if (this.phone) {
          if (!this.isAgree) {
            this.snackbarMessageModal = "Please agree the consent for Phone number.";
            this.snackbarColor = 'error';
            this.snackbarModal = true;
            return false;
          }
        }
        return true;
      },
      async subscribe() {
        if (this.$refs.form.validate() && this.isPhoneAndAgree()) {
          const subscriptionData = {
            firstName: this.firstName.trim(),
            lastName: this.lastName.trim(),
            email: this.email.trim(),
            password: this.password,
            phone: this.phone ? this.phone.trim() : '',
            zip: this.zipCode.trim(),
            city: this.city.trim(),
            state: this.state.trim(),
          };
          this.loading = true;
          try {
            const response = await axiosInstance.post('user', subscriptionData);
            const data = response.data;
            if (data.success) {
              this.screen1 = false; // show OTP screen
            } else {
              this.snackbarMessageModal = data.message;
              this.snackbarColor = 'error';
              this.snackbarModal = true;
            }
            this.loading = false;
          } catch (error) {
            this.snackbarMessageModal = error.response.data.message;
            this.snackbarColor = 'error';
            this.snackbarModal = true;
            this.loading = false;
          }
        }
      },
      cancel() {
        this.resetForm();
        this.$emit('signupCancel');
      },
      emailVerified() {
        if (this.phone && this.phone.length > 0 && /^\d{10}$/.test(this.phone.trim())) {
          this.type = 'Phone';
        } else {
          this.returnToParent();
        }
      },
      emailVerificationCancel() {
        this.emailVerified();
      },
      phoneVerified() {
        this.returnToParent();
      },
      phoneVerificationCancel() {
        this.returnToParent();
      },
      returnToParent() {
        this.resetForm();
        this.type = "Email"; //set default
        this.$emit('onSignupDone');
      },
      resetForm() {
        this.showModal = false;
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phone = '';
        this.zipCode = '';
        this.city = '';
        this.state = '';
        this.otp = '';
        this.screen1 = true;
        this.password = '';
        this.confirmPassword = '';
        this.isAgree = false;
        this.loading = false;
        if (this.$refs.form)
          this.$refs.form.reset();
      },
    },
  };
</script>

<style scoped>
  .action {
    cursor: pointer;
  }

  .snackbar-modal {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
</style>