<template>
  <v-container>
    <v-card>
      <div class="d-flex justify-end close-btn-container">
        <v-btn icon @click="closeView">
          <v-icon large class="red--text">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="headline">Terms and Conditions</v-card-title>
      <v-card-text>
        <p>Welcome to our SMS notification service. By opting in, you agree to the following terms:</p>

        <p><strong>1. Program Description:</strong> <strong>Striglia Enterprises, LCC (striglia.net)</strong> manages
          and protects your personal information. striglia.net operates the product <strong>"showusshows.com"</strong>,
          which provides SMS notifications to users about events in their area, approximately 5-8 messages monthly. By
          using our services, you consent to the practices described here.</p>

        <p><strong>2. Opt-Out Instructions:</strong> You may cancel at any time by texting "STOP" to our number. Upon
          opting out, you will receive a confirmation message. Msg & Data Rates May Apply.</p>

        <p><strong>3. Customer Support:</strong> For assistance, reply with "HELP" or contact us at <a
            href="mailto:contact@showusshows.com">contact@showusshows.com</a>.</p>

        <p><strong>4. Liability:</strong> Carriers are not responsible for delayed or undelivered messages.</p>

        <p><strong>5. Message and Data Rates:</strong> Standard message and data rates may apply for messages sent to
          and from you. For rate details, contact your wireless provider.</p>

        <p><strong>6. Privacy:</strong> We respect your privacy. Please review our <a href="/privacy-policy">Privacy
            Policy</a> for details on how we handle your information.</p>

        <p><strong>7. Frequency:</strong> Message frequency may vary depending on event activity and location relevance.
        </p>

        <p class="mb-1"><strong>8. User Conduct & Content Guidelines:</strong> You agree to use our services in
          compliance with applicable laws and our community standards. Specifically, you may not:</p>
        <ul>
          <li>Post, share, or distribute any <strong>inappropriate, illegal, offensive, defamatory, or harmful
              content</strong> through our platform.</li>
          <li>Engage in any <strong>harassment, hate speech, fraudulent activities, or the distribution of misleading
              information.</strong></li>
        </ul>

        <p class="mt-4 mb-1"><strong>9. Account Suspension & Termination:</strong> We reserve the right to
          <strong>suspend or permanently disable your account</strong> if you violate these terms. In cases of
          inappropriate content, your account may be:
        </p>
        <ul>
          <li><strong>Temporarily suspended for 30 days</strong> for a first violation.</li>
          <li><strong>Permanently disabled</strong> for repeated or severe violations.</li>
        </ul>
        <p class="mt-4">We may notify you of the reason for the action taken, but we are not obligated to do so. If you
          believe your account was suspended in error, you may contact <a
            href="mailto:contact@showusshows.com">contact@showusshows.com</a> for a review.</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'terms-condition-com',
    data() {
      return {
        isSourcePopup: this.isPopup
      };
    },
    methods: {
      closeView() {
        if(this.isSourcePopup){
          this.$emit('close');
        }else{
          this.$router.push('/');
        }
      },
    },
    props: {
      isPopup: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      isPopup(newVal) {
        this.isSourcePopup = newVal;
      }
    },
  }
</script>

<style scoped>
  .headline {
    font-weight: bold;
  }

  .close-btn-container {
    top: 10px;
    right: 15px;
    position: sticky;
    z-index: 1000;
  }
</style>