<template>
    <v-container>
      <v-card>
        <div class="d-flex justify-end close-btn-container">
          <v-btn icon @click="closeView" >
            <v-icon large class="red--text">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="headline">Privacy Policy</v-card-title>
        <v-card-text>
          <h4 class="mb-4">Effective Date: 10/01/2024</h4>
    
          <h4>1. Introduction</h4>
          <p>
            This Privacy Policy outlines how <strong>Striglia Enterprises, LCC (striglia.net)</strong> manages and protects your personal information. striglia.net operates the product <strong>"showusshows.com"</strong>, which provides SMS notifications to users about events in their area. By using our services, you consent to the practices described here.
          </p>
    
          <h4>2. Information We Collect</h4>
          <p><strong>Personal Information:</strong> Name, email address, and phone number (optional for SMS notifications).</p>
              
          <h4>3. How We Use Your Information</h4>
          <ul>
            <li>Send SMS updates, reminders, and cancellations for events.</li>
            <li>Confirm user consent via OTP during signup.</li>
            <li>Provide customer support and improve services.</li>
          </ul>
    
          <h4 class="mt-4">4. Call to Action</h4>
          <ul>
            <li><strong>Program Description:</strong> Users can opt in to receive SMS notifications from "showusshows.com," including updates, reminders, and cancellations about events in their area.</li>
            <li><strong>Originating Number:</strong> Messages will originate from the number <code>+15615109728</code>.</li>
            <li><strong>Organization:</strong> The SMS service is operated by <strong>striglia.net</strong>, the parent company of "showusshows.com."</li>
            <li><strong>Opt-In and Fees:</strong> By providing their phone number and verifying via OTP, users consent to receive SMS notifications. Msg & Data Rates May Apply.</li>
            <li><strong>Terms and Contact:</strong> All messages include opt-out instructions ("Reply STOP to unsubscribe, Msg & Data Rates May Apply"). For support, contact <a class="otherLinks" href="mailto:info@striglia.net">info@striglia.net</a> or <a class="otherLinks" href="mailto:contact@showusshows.com">contact@showusshows.com</a>. Review our <a class="otherLinks" href="/privacy-policy" target="_blank">Privacy Policy</a> and <a class="otherLinks" href="/terms-of-use" target="_blank">Terms of Use</a>.</li>
          </ul>
          <h4>5. SMS Notifications</h4>
          <p>When you subscribe to SMS notifications, you will receive a confirmation message detailing the program, message frequency, opt-out instructions, and customer support contact information. Msg & Data Rates May Apply</p>
          
          <h4>6. Data Sharing</h4>
          <p>We do not sell or rent your information. Data is shared only with:</p>
          <ul>
              <li><strong>Service Providers:</strong> Twilio is used solely to send SMS.</li>
              <li><strong>Legal Compliance:</strong> Disclosures required by law.</li>
          </ul>
    
          <h4 class="mt-4">7. Data Retention</h4>
          <p>We retain personal data as necessary for services or compliance. Opted-out numbers remain for internal records but receive no further messages.</p>
    
          <h4>8. Your Rights</h4>
          <ul>
            <li><strong>Opt-Out:</strong> Reply "STOP" to unsubscribe or update preferences in account settings.</li>
            <li><strong>Access and Corrections:</strong> Update information in your account.</li>
            <li><strong>Deletion:</strong> Request account deletion, subject to legal requirements.</li>
          </ul>
    
          <h4 class="mt-4">9. Security</h4>
          <p>We use industry-standard measures to protect your data but cannot guarantee absolute security.</p>
    
          <h4>10. Children’s Privacy</h4>
          <p>Our services are not directed to children under 13, and we do not knowingly collect their information.</p>
    
          <h4>11. Changes to This Privacy Policy</h4>
          <p>We may update this policy. Changes will be posted with an updated effective date.</p>
    
          <h4>12. Contact Us</h4>
          <p>For questions, contact us at:</p>
          <p>Email: info@striglia.net or contact@showusshows.com</p>
          <p>Address: Palm City, FL 34990</p>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'privacy-policy-com',
    data() {
      return {
        isSourcePopup: this.isPopup
      };
    },
    methods: {
      closeView() {
        if(this.isSourcePopup){
          this.$emit('close');
        }else{
          this.$router.push('/');
        }
      },
    },
    props: {
      isPopup: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      isPopup(newVal) {
        this.isSourcePopup = newVal;
      }
    },
  }
  </script>
  
  <style scoped>
  .headline {
    font-weight: bold;
  }
  .close-btn-container {
    top: 10px;
    right: 15px;
    position: sticky;
    z-index: 1000;
  }
  </style>
  