<template>
    <v-container>
        <v-card>
            <div class="d-flex justify-end close-btn-container">
                <v-btn icon @click="closeView">
                    <v-icon large class="red--text">mdi-close</v-icon>
                </v-btn>
            </div>
            <v-card-title class="headline">
                <v-icon left>mdi-help-circle</v-icon> Help & Guidelines
            </v-card-title>
            <v-card-text>
                <v-expansion-panels multiple>
                    <!-- Getting Started -->
                    <v-expansion-panel>
                        <v-expansion-panel-header> Getting Started </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <strong>Creating an Account</strong>
                            <ul>
                                <li>Click <strong>Login</strong> in the top-right corner of the header.</li>
                                <li>In the <strong>Login modal</strong>, click <strong>Sign Up</strong> to open the
                                    registration form.</li>
                                <li>Fill in your details (Name, Email, Phone, Zip Code, etc.).</li>
                                <li>Accept the <strong>Terms of Use and Privacy Policy</strong>.</li>
                                <li>Click <strong>Submit</strong> and complete <strong>OTP verification</strong> to
                                    activate your account.</li>
                            </ul>
                            <strong>Logging In</strong>
                            <ul>
                                <li>Click <strong>Login</strong> on the top-right corner.</li>
                                <li>Enter your registered email and password.</li>
                                <li>Click <strong>Login</strong> to access your dashboard.</li>
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Managing Account -->
                    <v-expansion-panel>
                        <v-expansion-panel-header> Managing Your Account </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <strong>Updating Profile</strong>
                            <ul>
                                <li>Click on your <strong>profile avatar</strong> (top-right corner).</li>
                                <li>Select <strong>Settings</strong> from the dropdown menu.</li>
                                <li>Go to <strong>Profile</strong> to update your personal details (except email).</li>
                                <li>Click <strong>Save Changes</strong> to update your information.</li>
                            </ul>
                            <strong>Changing Password</strong>
                            <ul>
                                <li>Click on your <strong>profile avatar</strong> (top-right corner).</li>
                                <li>Select <strong>Settings</strong> from the dropdown menu.</li>
                                <li>Navigate to <strong>Change Password</strong>.</li>
                                <li>Enter your current password and new password.</li>
                                <li>Click <strong>Update Password</strong> to save changes.</li>
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Events & Subscriptions -->
                    <v-expansion-panel>
                        <v-expansion-panel-header> Events & Subscriptions </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <strong>Viewing Events</strong>
                            <ul>
                                <li>Click <strong>Events</strong> to browse all listed events.</li>
                                <li>Enter <strong>Zip Code and Radius</strong> to browse all listed events in your
                                    location.</li>
                                <li>Use <strong>Filters</strong> to sort by <strong>Date, City, or Event Type</strong>.
                                </li>
                            </ul>
                            <strong>Subscribing to Email and SMS Notifications</strong>
                            <ul>
                                <li>Go to <strong>Settings → Subscriptions</strong>.</li>
                                <li>Check the <strong>Email and SMS Notification</strong> box.</li>
                                <li>Set the <strong>Notification Frequency</strong>.</li>
                            </ul>
                            <strong>Opting Out</strong>
                            <ul>
                                <li>Uncheck the <strong>SMS Notification</strong> box in <strong>Subscriptions</strong>.
                                </li>
                                <li>Alternatively, reply <strong>STOP</strong> to any SMS.</li>
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Posting an Event -->
                    <v-expansion-panel>
                        <v-expansion-panel-header> Posting an Event (For Hosts) </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <strong>Adding a New Event</strong>
                            <ul>
                                <li>Navigate to <strong>Add Event</strong>.</li>
                                <li>Fill in event details like <strong>Title, Description, Start and End Date,
                                        Location</strong>.</li>
                                <li><strong>The Recurring Event </strong>type will be automatically selected based on
                                    the Start and End Date.</li>
                                <li>Click <strong>Submit</strong> to post the event.</li>
                            </ul>
                            <strong>Managing Events</strong>
                            <ul>
                                <li>Click on your <strong>profile avatar</strong> (top-right corner).</li>
                                <li>Select <strong>Settings</strong> from the dropdown menu.</li>
                                <li>Go to <strong>Host Dashboard</strong> to view all events.</li>
                                <li>Click an event to <strong>Edit, Update, or Cancel</strong>.</li>
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Notifications & Alerts -->
                    <v-expansion-panel>
                        <v-expansion-panel-header> Notifications & Alerts </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <strong>How Notifications Work</strong>
                            <ul>
                                <li>Notifications are sent based on <strong>Subscription Settings</strong>.</li>
                                <li>Users receive notifications for events in the <strong>Zip Code and Radius</strong>
                                    they have
                                    subscribed to.</li>
                            </ul>

                            <strong>Types of Notifications</strong>
                            <ul>
                                <li><strong>Email Notifications:</strong> Users who have enabled <strong>Email
                                        Notification</strong>
                                    will receive event alerts via email.</li>
                                <li><strong>SMS Notifications:</strong> Users who have enabled <strong>SMS
                                        Notification</strong>
                                    will receive text message alerts on their verified phone number.</li>
                            </ul>

                            <strong>Notification Frequency Options</strong>
                            <ul>
                                <li>Users can choose when to receive notifications about upcoming events:</li>
                                <li><strong>1 Day Prior:</strong> Get a reminder one day before the event.</li>
                                <li><strong>2 Days Prior:</strong> Get a reminder two days before the event.</li>
                            </ul>

                            <strong>Managing Notifications</strong>
                            <ul>
                                <li>To add or modify your notification preferences, go to <strong>Subscriptions</strong>
                                    in the
                                    sidebar menu.</li>
                                <li>Click on <strong>+ button</strong> to add a subscription.</li>
                                <li>Click the <strong>Edit (✏️) icon</strong> to update your <strong>Zip Code, Radius,
                                        and Notification
                                        settings</strong>.</li>
                                <li>To stop receiving SMS notifications, uncheck the <stong>SMS Notification</stong> box
                                    or reply
                                    <strong>STOP</strong> to any received SMS.
                                </li>
                            </ul>

                            <p><strong>📌 Note:</strong> Message frequency is estimated based on event activity in the
                                selected area. Msg & Data Rates May Apply.</p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Troubleshooting & Support -->
                    <v-expansion-panel>
                        <v-expansion-panel-header> Troubleshooting & Support </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <strong>Common Issues</strong>
                            <ul>
                                <li><strong>Not receiving SMS?</strong></li>
                                <ul>
                                    <li>Ensure your phone number is verified via OTP.</li>
                                    <li>Check if <strong>SMS Notification</strong> is enabled in
                                        <strong>Subscriptions</strong>.
                                    </li>
                                </ul>
                                <!-- <li><strong>Login issues?</strong></li>
                                <ul>
                                    <li>Use <strong>Forgot Password</strong> on the login page.</li>
                                </ul> -->
                            </ul>
                            <strong>Need Help?</strong>
                            <ul>
                                <li>Email: <a href="mailto:support@showusshows.com">support@showusshows.com</a></li>
                                <!-- <li>SMS Support: Reply <strong>HELP</strong> to any message.</li> -->
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    export default {
        name: 'help-comp',
        data() {
            return {
                isSourcePopup: this.isPopup
            };
        },
        methods: {
            closeView() {
                if (this.isSourcePopup) {
                    this.$emit('close');
                } else {
                    if (this.$route.name !== 'home' && this.$route.name !== 'show-event-view-home') {
                        this.$router.push('/');
                    }
                }
            },
        },
        props: {
            isPopup: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            isPopup(newVal) {
                this.isSourcePopup = newVal;
            }
        },
    }
</script>
<style scoped>
    .close-btn-container {
        top: 10px;
        right: 15px;
        position: sticky;
        z-index: 1000;
    }
</style>